import React　from "react"


import styles from '@styles/pages/shop.module.scss'

import Layout from "@comp/layout"
import SEO from '@comp/seo'

import BtnDetail from '@comp/btn_detail'
import PageNav from '@comp/page_nav'
import PageTitle from '@comp/page_title'
import RecruitBlock from '@comp/recruit_block'


const navData = [
  {
    to: 'shibuya',
    label: '未来日本酒店 & SAKE BAR'
  },
]

// markup
const ShopPage = ({ location }) => {

  return (
    <Layout location={location} pageName="shop">
      <SEO
        title={`未来日本酒店 / 未来酒店 店舗一覧`}
        description={`未来日本酒店および未来酒店の店舗に関する情報はこちらをご覧ください。`}
        path={location.pathname}
      />
      <main className={styles.container}>
        <div className={styles.inner}>
          <PageTitle
            label={"店舗一覧"}
            labelEn='SHOP'
          />

          <PageNav
            data={navData}
            wrapClass={styles.nav}
            type={'Shop'}
          />
          
          <div className={styles.contents}>

            <section id="shibuya" className={styles.section_top}>
              <div className={styles.shop__img}>
                <img src="/images/shop/shop-img-shibuya.jpg" alt="未来日本酒店 & SAKE BAR"/>
              </div>
              <div className={styles.shop__body}>
                <h1 className={styles.shop__name}>未来日本酒店 & SAKE BAR</h1>
                <p className={styles.shop__description}>昨年末リニューアルした渋谷パルコ内の当店舗では、朝日酒造の「久保田」とコラボレーションし、「久保田」の新しい美味しさ・楽しみ方を体験できる「KUBOTA SAKE BAR」を併設しています！</p>
                <table className={styles.shop__info}>
                  <tbody>
                    <tr className={styles.shop__info__tr}>
                      <th className={styles.shop__info__th}>
                        所在地
                      </th>
                      <td className={styles.shop__info__td}>
                        <p className={styles.shop__info__address}>東京都渋谷区宇田川町15-1 渋谷PARCO B1F</p>
                        <p className={styles.shop__info__root}>※JR山手線・埼京線/東急東横線・田園都市線・京王井の頭線/東京メトロ銀座線・半蔵門線・副都心線「渋谷駅」より徒歩5分</p>
                        <p className={styles.shop__info__map}>
                          <BtnDetail 
                            href={'https://g.page/miraisakebar'}
                            target={'_blank'}
                            isTxtLink={true}
                          >
                            <span className={styles.shop__info__map__inner}>Google MAP</span>
                          </BtnDetail>
                        </p>
                      </td>
                    </tr>
                    <tr className={styles.shop__info__tr}>
                      <th className={styles.shop__info__th}>
                        営業時間
                      </th>
                      <td className={styles.shop__info__td}>
                        13:00-23:00（酒類の提供は22:00まで）
                      </td>
                    </tr>
                    <tr className={styles.shop__info__tr}>
                      <th className={styles.shop__info__th}>
                        電話番号
                      </th>
                      <td className={styles.shop__info__td}>
                        03-6455-3975
                      </td>
                    </tr>
                    <tr className={styles.shop__info__tr}>
                      <th className={styles.shop__info__th}>
                        定休日
                      </th>
                      <td className={styles.shop__info__td}>
                        不定休(渋谷パルコの営業に準ずる)
                      </td>
                    </tr>
                    <tr className={styles.shop__info__tr}>
                      <th className={styles.shop__info__th}>
                        SNS
                      </th>
                      <td className={styles.shop__info__td}>
                        <ul className={styles.shop__info__sns}>
                          <li className={styles.shop__info__sns__itm}>
                            <BtnDetail 
                              href={'https://www.facebook.com/miraisakebar'}
                              target={'_blank'}
                            >
                              <span className={styles.shop__info__sns__inner}>FACEBOOK</span>
                            </BtnDetail>
                          </li>
                          <li className={styles.shop__info__sns__itm}>
                            <BtnDetail 
                              href={'https://twitter.com/miraisakebar'}
                              target={'_blank'}
                            >
                              
                              <span className={styles.shop__info__sns__inner}>Twitter</span>
                            </BtnDetail>
                          </li>
                          <li className={styles.shop__info__sns__itm}>
                            <BtnDetail 
                              href={'https://www.instagram.com/miraisakebar/'}
                              target={'_blank'}
                            >
                              <span className={styles.shop__info__sns__inner}>Instagram</span>
                            </BtnDetail>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>

            <div className={styles.recruit}>
              <RecruitBlock />
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default ShopPage
