import React from "react"

import PropTypes from "prop-types"
import styles from '@styles/components/recruit_block.module.scss'

import BtnBox from '@comp/btn_box'


const propTypes = {
  title: PropTypes.string,
  label: PropTypes.string
}
const RecruitBlock = ({
  title,
  label,
}) => {
  return (
    <>
      <section className={styles.wrap}>
        <h1 className={styles.title}>{ title ? title: '未来酒店では、店舗スタッフを募集しています' }</h1>
        <div className={styles.btn}>
          <BtnBox
            to={'/recruit/'}
          >
            { label ? label : '詳しくはこちら'}
          </BtnBox>
        </div>
      </section>
    </>
  )
}
RecruitBlock.propTypes = propTypes
export default RecruitBlock
